@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7p7mwa');
  src:  url('fonts/icomoon.eot?7p7mwa#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7p7mwa') format('truetype'),
    url('fonts/icomoon.woff?7p7mwa') format('woff'),
    url('fonts/icomoon.svg?7p7mwa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Warning .path1:before {
  content: "\e90b";
  color: rgb(226, 69, 59);
}
.icon-Warning .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Warning .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e90a";
}
.icon-image:before {
  content: "\e905";
}
.icon-copy:before {
  content: "\e904";
}
.icon-reset:before {
  content: "\e903";
}
.icon-no_internet:before {
  content: "\e902";
}
.icon-correct:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-Incorrect:before {
  content: "\e908";
}
.icon-arrow_right:before {
  content: "\e900";
}
