@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;600&display=swap');

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  color: #121039;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  line-height: 28px;
  height: 100%;
  margin: 0;
  overflow: hidden
}

.header-bold {
  font-size: 60px;
  font-weight: 600;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.text-bold {
  font-weight: 600;
}
